import React, { useEffect, useState, useCallback } from 'react';
import {
  PanelProps,
  TimeRange,
  RawTimeRange,
  TimeZone,
  toUtc,
  SelectableValue,
  DateTime,
  dateTimeForTimeZone,
  dateTime,
} from '@grafana/data';
import { TimeRangePanelOptions, ExtendedVariableModel } from 'types';
import { TimeRangePicker, Legend, useTheme2, Select, VerticalGroup, HorizontalGroup } from '@grafana/ui';
import { getTemplateSrv, locationService } from '@grafana/runtime';
import { getShiftedTimeRange, getZoomedTimeRange } from 'helpers';
import _ from 'lodash';

interface Props extends PanelProps<TimeRangePanelOptions> { }

function makeTimeRange(from: DateTime | string, to: DateTime | string): TimeRange {
  return {
    from: toUtc(+from),
    to: toUtc(+to),
    raw: {
      from: toUtc(+from),
      to: toUtc(+to),
    },
  };
}

interface SelectableVoyage extends SelectableValue<{ start?: DateTime; end?: DateTime }> { }

function makeVoyageList(series: any[]): SelectableVoyage[] {
  const groups = _.groupBy(_.head(series).fields, 'name');

  const voyageList = _.zip<string, DateTime, DateTime>(
    groups.label[0].values.toArray(),
    groups.start[0].values.toArray(),
    groups.end[0].values.toArray()
  ).map(([label, start, end]) => ({ label: label, value: { start, end } }));

  return [...voyageList];
}

function getTimeRange(currentTimerange: TimeRange, key: String) {
  const vars = getTemplateSrv().getVariables() as ExtendedVariableModel[];
  const trTo = vars.find((v) => v.name === key + '_to');
  const trFrom = vars.find((v) => v.name === key + '_from');
  if (trTo && trFrom && trTo?.current.value && trFrom?.current.value) {
    return makeTimeRange(trFrom.current.value, trTo.current.value);
  } else {
    return currentTimerange;
  }
}

function getCurrentVessel(key: string): string | undefined {
  const vars = getTemplateSrv().getVariables() as ExtendedVariableModel[];
  return vars.find((v) => v.name === key + '_vessel')?.current.value;
}

function getVessels(key: String): Array<SelectableValue<string>> {
  const vars = getTemplateSrv().getVariables() as ExtendedVariableModel[];
  return (
    vars
      .find((v) => v.name === key + '_vessel')
      ?.options.map((e) => ({ label: e.text, value: e.value as string }))
      .filter((e) => e.label && e.value) ?? []
  );
}

function onMoveTimePicker(
  direction: number,
  range: TimeRange,
  onChangeTime: (range: RawTimeRange) => void,
  timeZone: TimeZone
) {
  const { from, to } = getShiftedTimeRange(direction, range);
  const nextTimeRange = {
    from: dateTimeForTimeZone(timeZone, from),
    to: dateTimeForTimeZone(timeZone, to),
  };

  onChangeTime(nextTimeRange);
}

function onMoveForward(range: TimeRange, onChangeTime: (range: RawTimeRange) => void, timeZone: TimeZone) {
  onMoveTimePicker(1, range, onChangeTime, timeZone);
}
function onMoveBack(range: TimeRange, onChangeTime: (range: RawTimeRange) => void, timeZone: TimeZone) {
  onMoveTimePicker(-1, range, onChangeTime, timeZone);
}

function onZoom(range: TimeRange, onChangeTime: (range: RawTimeRange) => void, timeZone: TimeZone) {
  const { from, to } = getZoomedTimeRange(range, 2);
  const nextTimeRange = {
    from: dateTimeForTimeZone(timeZone, from),
    to: dateTimeForTimeZone(timeZone, to),
  };

  onChangeTime(nextTimeRange);
}

export const TimeRangePanel: React.FC<Props> = (props) => {
  const {
    data: { series, timeRange },
    options,
    width,
  } = props;
  const theme = useTheme2();
  const id = options.text;
  const [tr, setTr] = useState<TimeRange>(getTimeRange(timeRange, id));
  const [timeZone, setTimeZone] = useState<TimeZone>('utc');
  const vesselOptions = getVessels(id);

  const [selectedVessel, setSelectedVessel] = useState<SelectableValue<string> | undefined>(
    vesselOptions.find((e) => e.value === getCurrentVessel(id))
  );
  const voyageOptions = makeVoyageList(series);

  const [selectedVoyage, setSelectedVoyage] = useState<SelectableVoyage | null>(null);

  useEffect(() => {
    const query = {
      [`var-${id}_to`]: tr.to.valueOf(),
      [`var-${id}_from`]: tr.from.valueOf(),
      [`var-${id}_vessel`]: selectedVessel?.value || '',
    };

    locationService.partial(query, true);
  }, [id, tr, selectedVessel]);

  const onChangeTime = useCallback((tr: RawTimeRange) => {
    setSelectedVoyage(null);
    setTr({
      from: dateTime(tr.from),
      to: dateTime(tr.to),
      raw: tr,
    });
  }, []);

  return (
    <VerticalGroup>
      <Legend style={{ margin: '0 0 0 20px' }}>
        <span
          style={{
            backgroundColor: theme.visualization.getColorByName(options.color),
            height: '1.2rem',
            width: '1.2rem',
            margin: '0 0.5rem',
            borderRadius: '50%',
            display: 'inline-block',
          }}
        />
        {options.text.toUpperCase()}
      </Legend>
      <Select
        options={vesselOptions}
        value={selectedVessel}
        noOptionsMessage="No vessels found"
        placeholder="Select a vessel"
        onChange={(e) => {
          setSelectedVessel(e);
        }}
      />
      <Select
        options={voyageOptions}
        value={selectedVoyage}
        noOptionsMessage="No voyages found"
        placeholder="Select voyage"
        onChange={(e: SelectableVoyage) => {
          setSelectedVoyage(e);
          setTr(makeTimeRange(e.value?.start || tr.from, e.value?.end || tr.to));
        }}
      />
      <HorizontalGroup justify="flex-end">
        <TimeRangePicker
          value={tr}
          onChange={onChangeTime}
          onChangeTimeZone={(timezone) => {
            setTimeZone(timezone);
            let zonedTr = {
              from: dateTimeForTimeZone(timeZone, tr.from),
              to: dateTimeForTimeZone(timeZone, tr.to),
            };
            onChangeTime(zonedTr);
          }}
          onMoveBackward={() => {
            onMoveBack(tr, onChangeTime, timeZone);
          }}
          onMoveForward={() => {
            onMoveForward(tr, onChangeTime, timeZone);
          }}
          onZoom={() => {
            onZoom(tr, onChangeTime, timeZone);
          }}
          hideText={width < 450}
        />
      </HorizontalGroup>
    </VerticalGroup>
  );
};
