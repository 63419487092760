import { PanelPlugin } from '@grafana/data';
import { TimeRangePanelOptions } from './types';
import { TimeRangePanel } from './TimeRangePanel';

export const plugin = new PanelPlugin<TimeRangePanelOptions>(TimeRangePanel).setPanelOptions((builder) =>
  builder
    .addTextInput({
      path: 'text',
      name: 'Timerange',
      description: 'The name of the time range',
      defaultValue: 'A',
    })
    .addColorPicker({
      path: 'color',
      name: 'Color',
      description: 'The color of the time range',
      defaultValue: 'rgb(150, 217, 141)',
    })
);
